import "../../Assets/Css/downloadSyllabus.style.css";
import React, { useState } from "react";
import {toast} from 'react-toastify'

import axios from 'axios'

function CourseCard({ courses , bro_name}) {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [college, setCollege] = useState("");
  const [isNotCollege , setIsNotCollege] = useState(false)

  const collegeOptions = [
    "Anand International College of Engineering, Jaipur",
    "Arya College of Engineering, Jaipur",
    "Agra College",
    "Arya Institute of Engineering Technology & Management, Jaipur",
    "Amity University",
    "Apex University",
    "Arka Jain University, Jamshedpur",
    "Arya College of Engineering and I.T, Jaipur",
    "Banasthali Vidyapith",
    "Babu Banarasi Das Northern India Institute of Technology, Lucknow",
    "Biyani Group Of Colleges",
    "B K Birla Institute of Engineering & Technology, Pilani",
    "B.S.A. College of Engineering & Technology, Mathura",
    "BITS Pilani Goa campus",
    "BITS Pilani Jaipur campus",
    "Bikaner Technical University (BTU)",
    "CVR College of Engineering, Hyderabad",
    "Dr. D. Y. Patil Vidyapeeth, Pune",
    "Global institute of Technology (GIT), Jaipur",
    "Government Engineering College (GEC), Banswara",
    "Government women engineering College, Ajmer",
    "Government Engineering College, Bharatpur",
    "Jagannath University (JU)",
    "Jaipur Engineering College and Research Centre (JECRE)",
    "Jodhpur Institute of Engineering & Technology, Jodhpur",
    "Jaipur National University (JNU)",
    "Jayoti Vidyapeeth Women’s University (JVWU)",
    "Lovely Professional University (LPU)",
    "Manipal University Jaipur",
    "MITS Pali (Mahaveer Institute of Technology & Science)",
    "Modi University (Dr K.N. Modi University)",
    "M.B.M. Engineering College, Jodhpur",
    "Maharishi Arvind Institute of Science & Management, Jaipur",
    "Malaviya National Institute of Technology, Jaipur",
    "Poornima College of Engineering",
    "Poornima Institute of Engineering and Technology",
    "Poddar College (Poddar Group of Institutions)",
    "Poornima University",
    "Pratap Institute of Technology & Science",
    "Rajasthan College of Engineering for Women (RCEW)",
    "Rajasthan Technical University (RTU), Kota",
    "S. S. Jain [Subodh College (or S. S. Jain Subodh P. G. College)]",
    "Swami Keshvanand Institute of Technology (SKIT)",
    "Stani Memorial P.G College",
    "SRM University, Bhopal",
    "St. Xavier's College, Jaipur",
    "The ICFAI University",
    "University of Engineering & Management, Jaipur",
    "Vivekananda Global University (VGU)",
    "Vyas College of Engineering and Technology, Jodhpur",
    "Vyas Institute of Engineering and Technology, Jodhpur",
    "Other",
  ];


  const handleDownload = () => {

    

    const data = {
      name :name , email :email , mobile  :mobile,brochure : bro_name ,  college_name : college
    }

    if(name.trim() == "" )
      {
        toast.error("Please Enter Your Name")
      }
      else if(email.trim() == ""){


         toast.error("Please Enter Your Email")

      }
      else if(mobile.trim() == ""){


                toast.error("Please Enter Your Mobile Number")

      }
      else if(college.trim() == "" || !college){


                toast.error("Please Enter Your College Name")

      }
      else{

      
    axios.post("https://internapi.learnandbuild.in/v2/add-downBro" , data).then((res)=>{
      if(res.data.status == 200)
      {
        toast.success(res.data.message)
        setShowModal(false)
        window.open(`https://internapi.learnandbuild.in/static/${bro_name}`, '_blank', 'noopener, noreferrer');

      }
    }).catch((err)=>{
      toast.error(err.response.data.message)
    })

  }


    // try {
    //   const response = await fetch(
    //     "https://internapi.learnandbuild.in/api/public/download-brochure",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ name, email, mobile }),
    //     }
    //   );

    //   // console.log(response);

    //   const Tempo = 'https://internapi.learnandbuild.in/static/'

    //   if (response.ok) {
    //     console.log("User registered successfully");

    //     // Close the modal
    //     setShowModal(false);

    //     // Trigger brochure download
    //     const brochurePath = courses.brochure;

    //     // Create the brochure URL
    //     const brochureUrl = brochurePath.startsWith("/")
    //       ? brochurePath
    //       : `/assets/SITP%202024${brochurePath}`;

    //     // Create an anchor element to trigger the download
    //     const downloadLink = document.createElement("a");
    //     downloadLink.href = brochureUrl;
    //     downloadLink.download = Tempo + `${courses.name}.pdf`; // Set the file name for download
    //     downloadLink.click();
    //   } else {
    //     console.error("Failed to register user");
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const getCollegeValue = (e)=>{
    if(e.target.value === "Other")
      {
        setIsNotCollege(true)
        setCollege("")
      }
      else{
        setCollege(e.target.value)
        setIsNotCollege(false)
      }
  }

  return (
    <div className="card p-3 h-100 d-flex justify-content-around">
      <img src={courses.image} alt="coursepic" />
      <h5 className="p-2 courseHead fw-bold">{courses.name}</h5>
      <h5 className="p-2 courseDesc">{courses.description}</h5>
      <button
        onClick={() => setShowModal(true)}
        style={{ fontSize: "12px", width: "100%", maxWidth: "200px" }}
        className="btn btn-outline-primary d-flex fw-bold"
      >
        Download Brochure
        <img
          src="assets/bx-download.png"
          className="img-fluid img-download-btn mx-3"
          alt="download button"
        />
      </button>

      {showModal && (
        <div className="modal modal-down ">
          <div className="modal-content-down text-centers">
            <div className="d-flex align-items-center justify-content-center  gap-5">
              <h5 className="heading-popup p-2 mt-2">
                Tell us a little about yourself
              </h5>
              <span
                className="close-down p-2"
                onClick={() => setShowModal(false)}
              >
                &times;
              </span>
            </div>
            <div className="p-3">
              <div className="d-flex justify-content-center mt-3">
                <input
                  type="text"
                  className="brochurePopup p-2"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <input
                  type="email"
                  className="brochurePopup p-2"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <input
                  type="tel"
                  className="brochurePopup p-2"
                  placeholder="Contact Number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div style={{height :  40}} class="d-flex justify-content-center mt-3">
  <select  onChange={getCollegeValue} class="form-select" id="floatingSelect" >
    <option selected value={null}>Select College</option>
    {collegeOptions.map((el,i)=>(
    <option  value={el} >{el}</option>
    ))}
  </select>
</div>
{isNotCollege ?
<div  className="d-flex justify-content-center mt-3">
                <input
                  type="tel"
                  className="brochurePopup p-2"
                  placeholder="College Name"
                  value={college}
                  onChange={(e) => setCollege(e.target.value)}
                />
              </div> : null}
            </div>
            <div className="text-center">
              <button
                className="btn btn-primary mt-4 p-2"
                onClick={handleDownload}
              >
                Download Brochure
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseCard;
