import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import ScrollIntoView from "react-scroll-into-view";

function Header() {
  return (
    <div className="header-container sticky-top w-100">
      <div className="d-flex justify-content-between ">
        <img
          src="./assets/logo-lnb.png"
          className="img imgLogo p-2 img-fluid my-auto d-none d-md-block"
          alt="logo-lnb"
        />

        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav navMenu d-flex gap-5 justify-content-center align-content-center mt-3">
                <ScrollIntoView selector="#AboutSection">
                  <a class="nav-link active  h2" aria-current="page" href="#0">
                    <p>About Program</p>
                  </a>
                </ScrollIntoView>
                <ScrollIntoView selector="#WhoShould">
                  <a class="nav-link h2" href="#0">
                    <p>Eligibility</p>
                  </a>
                </ScrollIntoView>
                <ScrollIntoView selector="#FeaturesP">
                  <a class="nav-link h2" href="#0">
                    <p> Features</p>
                  </a>
                </ScrollIntoView>
                <ScrollIntoView selector="#syllabusD">
                  <a class="nav-link h2 " href="#0">
                    <p> Get Syllabus</p>
                  </a>
                </ScrollIntoView>
                <ScrollIntoView selector="#FAQs">
                  <a class="nav-link h2 " href="#0">
                    <p> FAQs</p>
                  </a>
                </ScrollIntoView>
              </div>
            </div>
          </div>
        </nav>
        <div
          className="d-flex align-items-center my-auto"
          style={{ marginRight: "12px" }}
        >
          <ScrollIntoView
            selector="#formRegister "
            className="d-none d-md-block"
          >
            <button className="btn btn-primary btn-lg my-auto p-2">
              Register Now
            </button>
          </ScrollIntoView>
          <img
            src="./assets/logo-lnb.png"
            className="img imgLogo p-2 d-md-none d-block img-fluid my-0 "
            alt="logo-lnb"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
